import { ParentProps, Show } from "solid-js";

import { css } from "#style/css";
import { styled, Box } from "#style/jsx";

import { Typography } from "#root/components/Typography";

export type AppDashboardHeaderProps = ParentProps<{
  pageTitle: string;
}>;

export function AppDashboardHeader(props: AppDashboardHeaderProps) {
  return (
    <styled.header
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      gap="4"
      w="full"
      py="10"
      borderBottom="1px solid"
      borderBottomColor="darkGray"
      lg={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography
        tag="h1"
        class={css({
          color: "yellow",
        })}
      >
        {props.pageTitle}
      </Typography>

      <Show when={props.children}>
        <Box flexGrow="0" flexShrink="1">
          {props.children}
        </Box>
      </Show>
    </styled.header>
  );
}
